
@main-font-family: 'Roboto', Arial, sans-serif;
@titles-font: 'Roboto Condensed', 'Roboto', Arial, sans-serif;
@font_monospace: monospace, monospace;
@font-bold: 500;
@font-normal: 400;
@font-light: 300;
@font-color: #232323;

@link-color: #DA251C;
@primary-color: #DA251C;
@secondary-color: #FFE600;

@global-radius: 0px;

@border-color: #bbb;
@border-grey: #dddddd;

@grey: #666;
@dark-grey: #242424;
@light-grey: #e1e1e1;
@light-grey1: #f6f6f6;
@light-grey2: #f4f4f4;
@green: #6DB24B;
@orange: #FF9900;

@top-submenu-bg: #2D3032;
@top-submenu-border: darken(@top-submenu-bg, 5%);

@footer-color: #b5b5b5;
@footer-bg: #1B1C1D; 
@footer-hr: lighten(@footer-bg, 5%);

@bp-larger-than-mobile    : 400px;
@bp-larger-than-phablet   : 600px;
@bp-larger-than-tablet    : 900px;
@bp-larger-than-desktop   : 1000px;
@bp-larger-than-desktophd : 1200px;

@container-width: 1140px;
@container-width-larger-than-mobile: 90%;
@container-width-larger-than-phablet: 90%;
@container-width-larger-than-tablet: 90%;
@total-columns: 16;
@column-width: 100 / @total-columns; // calculates individual column width based off of # of columns
@column-margin: 4%; // space between columns
