/*-----------------------------------
Footer
---------------------------------*/
.footer {
	background: @footer-bg;
	padding: 3rem 0;
	color: @footer-color;
	font-size: 1.4rem;
	i {
		padding: 0 1rem 0 0;
		font-size: 16px;
		text-align: center;
	}
	a {
		color: #fff;
		font-weight: @font-normal;
		&:hover {
			color: @link-color;
			text-decoration: none;
		}
		
	}
	h4 {
		color: lighten(@footer-color, 10%);
		margin: 3rem 0 1rem;
	}
	p {
		margin-bottom: 1rem;
	}
	hr {
		border-color: @footer-hr;
		margin-top: 2rem;
		margin-bottom: 3rem;
	}
	ul {
		list-style: none;
		margin: 0;
		padding:  0 0 3rem;
		text-align: center;
		border-bottom: 1px solid @footer-hr;
		font-family: @titles-font;
		font-size: 1.6rem;
		li {
			background: none;
			display: inline;
			padding: 0 0.5rem;
			a {
				display: inline-block;
				padding: 10px;
				text-transform: uppercase;
			}
		}
	}
}