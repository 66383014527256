/*-----------------------------------
Skeleton
---------------------------------*/
html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-size: 62.5%;
}
body {
	margin: 0;
	font-size: 1.6em;
	line-height: 1.5;
	font-weight: @font-light;
	font-family: @main-font-family;
	color: @font-color;
	background: #fff;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
figure {
	display: block;
	margin: 1em 40px;
	margin-bottom: 2.5rem;
}
audio {
	display: inline-block;
	vertical-align: baseline;
	&:not([controls]) {
		display: none;
		height: 0;
	}
}
canvas, progress, video {
	display: inline-block;
	vertical-align: baseline;
}
[hidden], template {
	display: none;
}
abbr[title] {
	border-bottom: 1px dotted;
}
dfn {
	font-style: italic;
}
mark {
	background: #ff0;
	color: #000;
}
small {
	font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
	max-width: 100%;
	height: auto;
	border: none;
}
svg:not(:root) {
  overflow: hidden;
}
blockquote, dl, form  {
	margin-bottom: 2.5rem;
}
kbd, samp {
	font-family: @font_monospace;
	font-size: 1em;
}
/*-----------------------------------
Code
---------------------------------*/
code {
  font-family: @font_monospace;
  padding: .2rem .5rem;
  margin: 0 .2rem;
  font-size: 90%;
  white-space: nowrap;
  background: lighten(@light-grey, 6.4%);
  border: 1px solid @light-grey;
  border-radius: @global-radius;
}

pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}
/*-----------------------------------
Buttons
---------------------------------*/
.button,
button {
  display: inline-block;
  height: 44px;
  padding: 0 30px;
  color: @font-color;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: @global-radius;
  border: 1px solid @border-color;
  cursor: pointer;
  box-sizing: border-box;
}

input {
  &[type="submit"],
  &[type="reset"],
  &[type="button"] {
    display: inline-block;
    height: 44px;
    padding: 0 30px;
    color: @font-color;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 44px;
    letter-spacing: .1rem;
    text-transform: none;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border-radius: @global-radius;
    border: 1px solid @border-color;
    cursor: pointer;
    box-sizing: border-box;
  }
}

.button:hover,
button:hover {
  color: #000;
  border-color: @dark-grey;;
  outline: 0;
  text-decoration: none;
}

input {
  &[type="submit"]:hover,
  &[type="reset"]:hover,
  &[type="button"]:hover {
    color: #000;
    border-color: lighten(@dark-grey, 10%);
    outline: 0;
    text-decoration: none;
  }
}

.button:focus,
button:focus {
  color: #000;
  border-color: lighten(@dark-grey, 10%);
  outline: 0;
}

input {
  &[type="submit"]:focus,
  &[type="reset"]:focus,
  &[type="button"]:focus {
    color: #000;
    border-color: lighten(@dark-grey, 10%);
    outline: 0;
  }
}

.button.button-primary,
button.button-primary {
  color: #fff;
  background-color: @primary-color;
  border-color: @primary-color;
}

input {
  &[type="submit"].button-primary,
  &[type="reset"].button-primary,
  &[type="button"].button-primary {
    color: #fff;
    background-color: @primary-color;
    border-color: @primary-color;
  }
}

.button.button-primary:hover,
button.button-primary:hover {
  color: #fff;
  background-color: @dark-grey;
  border-color: @dark-grey;
}

input {
  &[type="submit"].button-primary:hover,
  &[type="reset"].button-primary:hover,
  &[type="button"].button-primary:hover {
    color: #fff;
    background-color: @dark-grey;
    border-color: @dark-grey;
  }
}

.button.button-primary:focus,
button.button-primary:focus {
  color: #fff;
  background-color: @primary-color;
  border-color: @primary-color;
}

input {
  &[type="submit"].button-primary:focus,
  &[type="reset"].button-primary:focus,
  &[type="button"].button-primary:focus {
    color: #fff;
    background-color: @primary-color;
    border-color: @primary-color;
  }
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    height: 38px;
    padding: 6px 10px; // The 6px vertically centers text on FF, ignored by Webkit
    background-color: #fff;
    border: 1px solid lighten(@border-color, 8.8%);
    border-radius: @global-radius;
    box-shadow: none;
    box-sizing: border-box;
  }
}

.button.button-primary1 {
	color: #fff;
	background-color: fade(#000, 30%);
	border-color: @dark-grey;
	padding: 0 16px;
	&:hover {
		color: @primary-color;
		background-color: @dark-grey;
	}
}
.button.button-primary2 {
	color: #fff;
	background-color: fade(#000, 30%);
	border-color: @dark-grey;
	padding: 0 16px;
	font-weight: 600;
	&:hover {
		color: @primary-color;
		background-color: @dark-grey;
	}
}
button[disabled] {
	cursor: default;
}
/*-----------------------------------
Forms
---------------------------------*/
textarea,
select {
  height: 38px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  border: 1px solid lighten(@border-color, 8.8%);
  border-radius: @global-radius;
  box-shadow: none;
  box-sizing: border-box;
}
input {
  &[type="email"],
  &[type="number"],
  &[type="search"],
  &[type="text"],
  &[type="tel"],
  &[type="url"],
  &[type="password"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}
input {
  &[type="email"]:focus,
  &[type="number"]:focus,
  &[type="search"]:focus,
  &[type="text"]:focus,
  &[type="tel"]:focus,
  &[type="url"]:focus,
  &[type="password"]:focus {
    border: 1px solid @primary-color;
    outline: 0;
  }
}
textarea:focus,
select:focus {
  border: 1px solid @primary-color;
  outline: 0;
}
label,
legend {
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: @font-bold;
}
fieldset {
  padding: 0;
  border-width: 0;
}
input {
  &[type="checkbox"],
  &[type="radio"] {
    display: inline;
  }
}

label > .label-body {
  display: inline-block;
  margin-left: .5rem;
  font-weight: @font-light;
}
optgroup {
	color: inherit;
	font: inherit;
	margin: 0;
	font-weight: 600;
}

/*-----------------------------------
Lists
---------------------------------*/
ul {
  list-style: circle outside;
  margin-left: 3rem;
}
ol {
  list-style: decimal outside;
  padding-left: 3rem;
  margin-top: 0;
}
ul {
  padding-left: 0;
  margin-top: 0;
  ul, ol {
    margin: 1.5rem 0 1.5rem 3rem;
  }
}
ol {
  ol, ul {
    margin: 1.5rem 0 1rem 3rem;
  }
}
li {
	margin-bottom: 0rem;
}
/*-----------------------------------
Utilities
---------------------------------*/
.u-full-width {
	width: 100%;
	box-sizing: border-box;
}
.u-max-full-width {
	max-width: 100%;
	box-sizing: border-box;
}
.u-pull-right {
	float: right;
}
.u-pull-left {
	float: left;
}
/*-----------------------------------
Grid
---------------------------------*/
.grid-column-width(@n) {
  width: @column-width * @n - (@column-margin*(@total-columns - @n)/@total-columns);
}

.grid-offset-length(@n) {
  margin-left: @column-width * @n - (@column-margin*(@total-columns - @n)/@total-columns) + @column-margin;
}
.container {
	position: relative;
	width: 100%;
	max-width: @container-width;
	margin: 0 auto;
	padding: 0 20px;
	box-sizing: border-box;
}
.column, .columns {
	width: 100%;
	float: left;
	box-sizing: border-box;
}
@media (min-width: @bp-larger-than-mobile) {
  .container {
    width: @container-width-larger-than-mobile;
    padding: 0;
  }
}
@media (min-width: @bp-larger-than-tablet) {
  .container {
    width: @container-width-larger-than-tablet;
  }
  .column,
  .columns {
    margin-left: @column-margin;
  }
  .column:first-child,
  .columns:first-child {
    margin-left: 0;
  }

  .one.column,
  .one.columns          { .grid-column-width(1);  }
  .two.columns          { .grid-column-width(2);  }
  .three.columns        { .grid-column-width(3);  }
  .four.columns         { .grid-column-width(4);  }
  .five.columns         { .grid-column-width(5);  }
  .six.columns          { .grid-column-width(6);  }
  .seven.columns        { .grid-column-width(7);  }
  .eight.columns        { .grid-column-width(8);  }
  .nine.columns         { .grid-column-width(9);  }
  .ten.columns          { .grid-column-width(10); }
  .eleven.columns       { .grid-column-width(11); }
  .twelve.columns       { .grid-column-width(12); }
  .sixteen.columns       { width: 100%; margin-left: 0;  }

  .one-third.column     { .grid-column-width(4);  }
  .two-thirds.column    { .grid-column-width(8);  }

  .one-half.column      { .grid-column-width(8);  }


  /* Offsets */
  .offset-by-one.column,
  .offset-by-one.columns       { .grid-offset-length(1);  }
  .offset-by-two.column,
  .offset-by-two.columns       { .grid-offset-length(2);  }
  .offset-by-three.column,
  .offset-by-three.columns     { .grid-offset-length(3);  }
  .offset-by-four.column,
  .offset-by-four.columns      { .grid-offset-length(4);  }
  .offset-by-five.column,
  .offset-by-five.columns      { .grid-offset-length(5);  }
  .offset-by-six.column,
  .offset-by-six.columns       { .grid-offset-length(6);  }
  .offset-by-seven.column,
  .offset-by-seven.columns     { .grid-offset-length(7);  }
  .offset-by-eight.column,
  .offset-by-eight.columns     { .grid-offset-length(8);  }
  .offset-by-nine.column,
  .offset-by-nine.columns      { .grid-offset-length(9);  }
  .offset-by-ten.column,
  .offset-by-ten.columns       { .grid-offset-length(10); }
  .offset-by-eleven.column,
  .offset-by-eleven.columns    { .grid-offset-length(11); }


  .offset-by-one-third.column, 
  .offset-by-one-third.columns  { .grid-offset-length(4);  }
  .offset-by-two-thirds.column, 
  .offset-by-two-thirds.columns { .grid-offset-length(8);  }

  .offset-by-one-half.column,   
  .offset-by-one-half.column   { .grid-offset-length(6);  }


}
/*-----------------------------------
Clearing
---------------------------------*/
.container:after,
.row:after,
.u-cf {
  content: "";
  display: table;
  clear: both;
}