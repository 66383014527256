/*-----------------------------------
RWD
---------------------------------*/
@media (min-width:320px) {
}
@media (min-width:600px) {
	#logo1 {
		float: left;
		display: inline;
		}
	.lg {
		text-align: right;
		float: none;
		display: block;
		padding: 1rem 0 1.2rem;
	}
	#menu-icon {
		float: right;
		margin-left: 1rem;
	}
	.search-box {
		float: right;
	}
	#nav {
		ul {
			top: 8.6rem;
		}
	}
	#sidenav {
		margin-top: 4.9rem;
	}
	img.prawa {
	margin: 0.5rem 0px 3rem 3rem;
	float: right;
	}
	img.lewa {
	margin: 0.5rem 3rem 3rem 0px;
	float: left;
	}
}
@media (min-width:750px) {
	.content {
		padding: 5rem 0;
	}
	#sidenav {
		margin-top: 4.9rem;
	}
	.navigation {
		text-align: right;
	}
	.block {
		width: 48%;
		margin: 0 1% 2rem;
		height: 190px;
	}
	.block4 {
		width: 48%;
		margin: 0 1% 2rem;
		height: auto;
	}
		.offer-home .block {
		height: 110px;
	}
}
@media (min-width:1100px) {
	.lg {
		padding: 0.5rem 0 0.8rem;
	}
	a.home {
		display: inline-block;
	}
	.page-full {
		padding-top: 8.8rem;
		position: relative;
	}
	.block {
		width: 31%;
		margin: 0rem 1% 2rem;
	}
	.block4 {
		width: 23%;
		margin: 0rem 1% 2rem;
		height: 230px;
	}
	.navigation {
		position: fixed;
		z-index: 900;
	}
	#nav {
		position: relative;
		ul {
			position: relative;
			top: 0;
			height: 45px;
			width: auto;
		}
		>a.przycisk {
			padding: 0px 12px;
			line-height: 45px;
			margin-right: 0;
		}
	}
	#menu-top {
		display: inline-block !important;
		vertical-align: middle;
		li {
			float: left;
			position: relative;
			z-index: 100;
			&:hover {
				color: @link-color;
				>ul {
					display: block;
				}
			}
		}
		li.menu-last {
		padding-right: 0;	
		}
		a {
			background: none;
			border-bottom: 0;
			color: @dark-grey;
			&:hover {
						color: @link-color;
						background: none;
					}
		}
		ul {
			position: absolute;
			left: 0;
			top: 4.7rem;
			display: none;
			width: 34rem;
			height: auto;
			background: @top-submenu-bg;
			text-align: left;
			z-index: 110;
			border-top: 5px solid @primary-color;
			li {
				float: none;
				a {
					padding-left: 1.5rem;
					color: #fff;
					border-bottom: 1px solid @top-submenu-border;
					&:hover {
						background: darken(@top-submenu-bg, 10%);
						text-decoration: none;
						color: #fff;
					}
				}
				ul {
					display: none;
				}
				&:hover {
					> ul {
						margin: 0;
						padding: 0;
						position: absolute;
						z-index: 120;
						display: block;
						left: 27rem;
						top: 0px;
						width: 300px;
						background: darken(@top-submenu-bg, 5%);
						a {
							padding-left: 1.5rem;
							font-size: 1.4rem;
						}
					}
				}
			}
		}
		>li.menu-current, >li.menu-parent {
		>a {
			background: none;
			color: @link-color;
		}
	}
}
	#menu-icon {
		display: none;
	}
}