/*-----------------------------------
Main
---------------------------------*/
h1, h2, h3, h4, h5, h6 {
	margin-top:0;
	margin-bottom:1.5rem;
	color: #000;
	font-family: @titles-font;
	font-weight: 400;
	font-style:normal;
	text-transform: uppercase;
}
h1 {
	font-size:2.8rem;
	line-height:1.2;
	margin-bottom:2rem;
	position: relative;
	display: inline-block;
	font-family: @titles-font;
}
h2{font-size:2.4rem;line-height:1.25;}
h3{font-size:1.8rem;line-height:1.3;}
h4{font-size:1.6rem;line-height:1.35;}
h5{font-size:1.6rem;line-height:1.5;}
h6{font-size:1.5rem;line-height:1.6;}
p {
	margin-top: 0;
	margin-bottom: 2.5rem;
}
b, strong {
	font-weight: @font-bold;
}
.lead {
	color: @dark-grey;
	font-family: @main-font-family;
	font-weight: @font-light;
	font-size: 2rem;
	margin-bottom: 5rem;
}
hr {
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	margin-top: 1.5rem;
	margin-bottom: 4rem;
	border-width: 0;
	border-top: 1px solid @light-grey;
}
a {
	background-color: transparent;
	color: @link-color;
	text-decoration: none;
	-moz-transition: all 0.2s linear 0s;
	-webkit-transition: all 0.2s linear 0s;
	-o-transition: all 0.2s linear 0s;
	transition: all 0.2s linear 0s;
	font-weight: @font-normal;
	&:active {
		outline: 0;
	}
	&:hover {
		outline: 0;
		text-decoration: none;
		color: #000;
		img.ramka {
			border: 1px solid darken(@border-grey, 20%);
		}
	}
	img.ramka {
		padding: 5px;
		background: #fff;
		border: 1px solid @border-grey;
	}
}
a.wiecej {
	position: relative;
	padding: 0;
		&:after {
		font-family: FontAwesome;
        top:0;
        right: 0;
        padding-left: 0.5rem;
        content: "\f0da"; 
	}
}
a.powrot {
	position: relative;
	padding: 0;
		&:before {
		font-family: FontAwesome;
        top:0;
        left: 0;
        padding-right: 0.8rem;
        content: "\f0d9";
	}
}
a.pdf {
	position: relative;
	padding: 0;
		&:before {
		font-family: FontAwesome;
        top:0;
        left: 0rem;
        padding-right: 0.8rem;
        content: "\f1c1";
		}
}
a.przycisk {
	background: @primary-color;
	color: #fff;
	text-align: center;
	padding: 10px 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 16px;
	display: inline-block;
	text-transform: uppercase;
	font-weight: @font-normal;
	&:hover {
		background: @dark-grey;
		color: #fff;
		cursor: pointer;
	}
}
#top-btn {
  display: none;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: @primary-color;
  color: #fff;
  cursor: pointer;
  padding: 11px 15px;
}
#top-btn:hover {
  background-color: @dark-grey;;
}
blockquote {
	font-size: 1.6rem;
	color: #000;
	margin: 0 0 2rem;
	padding: 0.9rem 2rem 0px 2rem;
	border-left: 0.5rem solid @primary-color;
	p {
		font-size: 1.6rem;
		color: @dark-grey;
	}
	cite {
		display: block;
		font-size: 1.6rem;
		color: @dark-grey;
		&:before {
			content: "\2014 \0020";
		}
		a {
			color: @link-color;
			&:visited {
				color: @dark-grey;
			}
		}
	}
}
.breadcrumb {
    padding: 0;
    margin-bottom: 1rem;
    list-style: none;
    border-radius: 4px;
    font-size: 13px;
    line-height: 24px;
    a {
    	font-weight: @font-normal;
    	padding: 0 0.5rem;
    }
    span.menu-current a {
    	color: @grey;
    }
}
/*-------------------------------------------------
Tables
-------------------------------------------------*/
table {
	border-collapse: separate;
	border-spacing: 1px;
	margin-bottom: 2rem;
}
td {
	padding: 10px;
	text-align: left;
	p {
		margin: 0;
	}
}
th {
	padding: 10px;
	text-align: left;
	background: @primary-color;
    color: #fff;
    p {
		margin: 0;
	}
}
table.cookies {
	border-collapse: separate;
	border-spacing: 2px;
	td {
		padding: 10px;
	}
}
table.czesci {
	width: 100%;
}
.table-striped > tbody > tr:nth-of-type(2n+1) {
    background-color: @light-grey2;
}
.table-responsive {
	width: 100%;
	overflow-x: auto;
	margin-bottom: 20px;
}
/*-------------------------------------------------
Page
-------------------------------------------------*/
.wrap {
	width: 100%;
	margin: 0 auto;
	clear: both;
	height: auto;
}
.page-full {
	overflow-x: hidden;
	padding: 0;
	z-index: 1;
}
.top {
	p {
		margin-bottom: 0;
}
	img {
		display: block;
	}
}
.bg-grey {
	background: @light-grey2;
}
.bg-red {
	background: @primary-color;
	color: #fff;
	h2 {
		color: #fff;
		font-family: @main-font-family;
	}
	p {
		font-size: 18px;
	}
}
.top-page {
	background: url(../images/top-bg.jpg) no-repeat center center;
	background-repeat: no-repeat;
	background-position-x: center;
	background-position-y: center;
	background-size: cover;
	position: relative;
	padding: 8rem 0;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(0,0,0,.3);
	}
}
.top-page {
	h1 {
		color: #fff;
		font-size: 4rem;
		font-weight: 600;
		margin: 0;
		border-bottom: 0px solid @secondary-color;
	}
}
.content {
	padding: 4rem 0;
}
.sitemap {
	li {
		padding: 0.6rem 0;
	}
}
/*-----------------------------------
  Bloki
---------------------------------*/
.block {
	width: 100%;
	float: left;
	height: auto;
	position: relative;
	margin-bottom: 2rem;
	p {
		margin-bottom: 0;
	}
	h2, h3 {
		font-size: 1.8rem;
		position: relative;
		padding-bottom: 1rem;
		margin-bottom: 1rem;
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 118px;
			height: 2px;
			background-color: rgba(0,0,0,.1);
			bottom: 0;
			left: ~"calc(50% - 59px)";			
		}
	}
	p img {
		display: block;
		position: relative;
		max-width: 100%;
		height: auto;
		margin: 0 auto 1rem;
		&:before, &:after {
		    content: '';
		    display: block;
		    position: absolute;
		    width: 15px;
		    height: 15px;
		    border-style: solid;
		    border-width: 1px;
		}
		&:before {
		    border-color: rgba(0,0,0,.3) transparent transparent rgba(0,0,0,.3);
		    top: -1px;
		    left: -1px;
		}
			&:after {
		    border-color: transparent rgba(0,0,0,.3) rgba(0,0,0,.3) transparent;
		    bottom: -1px;
		    right: -1px;
		}
	}
	a {
		font-weight: @font-normal;
	}
}
.box {
	background: @light-grey1 url(../images/tlo-box.jpg) no-repeat center center;
	background-size: cover;
	padding: 2.5rem 2.5rem;
	margin-bottom: 2rem;
	box-sizing: border-box;
	text-align: center;
	h2, h3 {
	}
	a {
		font-weight: 400;
		&:hover {
			}
	}
	&:before, &:after {
    content: '';
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    border-style: solid;
    border-width: 1px;
	}
	&:before {
    border-color: rgba(0,0,0,.3) transparent transparent rgba(0,0,0,.3);
    top: 1px;
    left: 1px;
	}
	&:after {
    border-color: transparent rgba(0,0,0,.3) rgba(0,0,0,.3) transparent;
    bottom: 1px;
    right: 1px;
	}
}
/*-----------------------------------
Google map
---------------------------------*/
#mapa {
	background: url(../images/loading.gif) no-repeat center center;
	text-align: center;
	img {
		box-shadow: none;
		max-width: none;
	}
}
#info {
	margin: 0;
	padding: 0;
	font-family: @main-font-family;
	font-size: 12px !important;
	width: 200px;
}
/*-----------------------------------
Other
---------------------------------*/
.remove-bottom {
	margin-bottom: 0 !important;
}
.add-top {
	margin-top: 2.5rem !important;
}
.add-bottom {
	margin-bottom: 2.5rem !important;
}
.float-left {
	float: left;
	display: inline;
	overflow: hidden;
}
.float-right {
	float: right;
	display: inline;
	overflow: hidden;
}
.italic {
	font-style: italic;
}
.uppercase {
	text-transform: uppercase;
}
.align-left {
	text-align: left;
}
.align-right {
	text-align: right;
}
.align-center {
	text-align: center;
}
.align-justify {
	text-align: justify;
}
.clear {
	clear: both;
	width: 100%;
	line-height: 0;
	font-size: 0;
	height: 0;
	overflow: hidden;
}
img.prawa {
	margin: 5px auto;
	float: none;
	display: block;
}
img.lewa {
	margin: 5px auto;
	float: none;
	display: block;
}
img.ramka, a img.ramka {
	padding: 3px;
	background: #fff;
	border: 1px solid @border-grey;
}
a:hover {
		img.ramka {
			border: 1px solid darken(@border-grey, 20%);
		}
}