/*-------------------------------------------------------------
    RSlider
-------------------------------------------------------------*/
.rSlider { overflow: hidden; width: 100%; position: relative; }
.rSlider > .rSlider--view { width: 1000%; overflow: hidden; display: block; overflow: hidden; }
.rSlider > .rSlider--view:before { content: " "; display: block; }
.rSlider > .rSlider--view > .rSlider--slide { float: left; overflow: hidden; position: relative; display: block; overflow: hidden; }
.rSlider > .rSlider--view > .rSlider--slide:before { content: " "; display: block; }
.rSlider > .rSlider--view > .rSlider--slide > .rSlider--image { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-size: cover; background-position: right center; z-index: 0; }
.rSlider > .rSlider--view > .rSlider--slide > .rSlider--container { padding: 130px 0px; max-width: @container-width; margin: auto; width: 90%; position: relative; z-index: 2; top: 50%; left: 0; -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%); transform: translate(0, -50%); display: block; overflow: hidden; }
.rSlider > .rSlider--view > .rSlider--slide > .rSlider--container:before { content: " "; display: block; }
.rSlider > .rSlider--view > .rSlider--slide > .rSlider--bg-color { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: block; z-index: 1; }
.rSlider > .rSlider--dots-controls { padding: 20px; position: absolute; bottom: 0; left: 50%; -webkit-transform: translate(-50%, 0); -ms-transform: translate(-50%, 0); transform: translate(-50%, 0); z-index: 2; display: block; overflow: hidden; }
.rSlider > .rSlider--dots-controls:before { content: " "; display: block; }
.rSlider > .rSlider--dots-controls button { float: left; cursor: pointer; background: #fff; height: 16px; width: 16px; opacity: 0.4; border: 3px solid #fff; margin-right: 15px; margin-bottom: 5px; padding: 0;}
.rSlider > .rSlider--dots-controls button:last-of-type { margin-right: 0; margin-bottom: 0; }
.rSlider > .rSlider--dots-controls button.active { background: transparent; border: 2px solid #fff; opacity: 1; }
.rSlider > .rSlider--dots-controls button:focus { outline: none !important;}
.rSlider > .rSlider--arrow-controls { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
.rSlider > .rSlider--arrow-controls span { width: 40px; padding: 10px; position: absolute; overflow: hidden; top: 0; height: 100%; z-index: 3; text-align: center; cursor: pointer; }
.rSlider > .rSlider--arrow-controls span:first-child { left: 0; }
.rSlider > .rSlider--arrow-controls span:last-child { right: 0; }
.rSlider > .rSlider--arrow-controls span:hover button { color: rgba(255, 255, 255, 0.6); border: none;}
.rSlider > .rSlider--arrow-controls span button:focus { outline: none !important;}
.rSlider > .rSlider--arrow-controls button { 
	position: relative; width: 40px; height: 40px; top: 50%; 
	color: #fff; color: rgba(255, 255, 255, 0.3); -webkit-transform: translate(0, -50%); -ms-transform: translate(0, -50%); 
	transform: translate(0, -50%); background: transparent; border: none; 
	cursor: pointer;
	font-size: 40px;
	padding: 0;
	}

.rSlider.css-transitions .rSlider--view { -webkit-transition: all 0.6s ease 0s; transition: all 0.6s ease 0s; }
.rSlider.css-transitions .rSlider--slide { -webkit-transition: all 0.6s ease 0s; transition: all 0.6s ease 0s; }
.rSlider.css-transitions .rSlider--bg-color { -webkit-transition: all 0.6s ease 0s; transition: all 0.6s ease 0s; }
.rSlider.css-transitions .rSlider--dots-controls button { -webkit-transition: all 0.6s ease 0s; transition: all 0.6s ease 0s; }
.rSlider.css-transitions .rSlider--arrow-controls button { -webkit-transition: all 0.6s ease 0s; transition: all 0.6s ease 0s; }

.rSlider .rSlider--bg-color { background: rgba(0, 0, 0, 0); }
.rSlider .slide-styled { width: 100%; max-width: @container-width; }
.rSlider .slide-styled p {
		font-size: 30px;
		font-weight: 600;
		font-family: @main-font-family;
		line-height: 40px;
		color: #fff;
		b, strong {
			font-weight: 400;
		}
		&:nth-child(1) {
			font-size: 40px;
			line-height: 50px;
			font-family: @main-font-family;
			font-weight: 600;
			text-transform: none;
		}
	}
.rSlider .slide-styled a, .rSlider .slide-styled button { background: #DE6E70; display: inline-block; padding: 20px 40px; color: #fff; text-decoration: none; margin: auto; }
.rSlider:hover .rSlider--bg-color { background: none; }

@media (min-width: 0) { .rSlider { font-size: 0.7rem; } }
@media (min-width: 800px) { .rSlider { font-size: 1rem; } }
@media (min-width: 1400px) { .rSlider { font-size: 1.2rem; } }
@media (min-width: 0) and (max-width: 1000px) { .rSlider { text-align: center;} .rSlider .rSlider--bg-color {background: rgba(0, 0, 0, 0.35);} .rSlider:hover .rSlider--bg-color {background: rgba(0, 0, 0, 0.3);} }