/*-------------------------------------------------
Header - logo, top navigation, social buttons
-------------------------------------------------*/
.navigation {
	background: rgba(255, 255, 255, 0.95);
	position: relative;
	box-shadow: 0 0 5px #aaa;
	text-align: center;
	z-index: 10;
	border-top: 0px solid @secondary-color;
}
#logo1 {
	text-align: center;
	float: none;
	display: block;
	padding: 1.5rem 0;
	img {
		width: 27rem;
		display: block;
		margin: 0rem auto 0rem;
	}
}
.lg {
	padding: 0rem;
	z-index: 120;
	vertical-align: middle;
	display: inline-block; 
	float: left;
	a {
		color: @font-color;
		font-family: @titles-font;
		font-size: 1.6rem;
		padding: 0.5rem 1.5rem;
		margin-right: 0px;
		background: @light-grey1;
		&:hover {
		color: @primary-color;
		}
	}
}
/*-------------------------------------------------------------
     Top navigation
-------------------------------------------------------------*/
a.home {
	font-weight: 400;
	transition: all .3s ease;
	display: none;
	color: @dark-grey;
	line-height: 45px;
	font-size: 1.8rem;
	padding: 0 0 0 1rem;
	vertical-align: middle;
	&:hover {
		color: @link-color;
	}
}
#nav {
	margin: 0;
	padding: 0;
	float: right;
	font-family: @main-font-family;
	>a.przycisk {
		padding: 0px 12px;
		line-height: 45px;
		margin-right: 1rem;
	}
	ul {
		list-style: none;
		padding: 0px;
		margin: 0;
		clear: both;
		position: absolute;
		top: 12.9rem;
		right: 0;
		width: 31rem;
		height: auto !important;
		z-index: 1000;
		text-align: left;
		display: none;
	}
}
#menu-icon {
	height: 40px;
	background: #fff;
	padding: 0 20px;
	cursor: pointer;
	display: inline-block;
	position: relative;
	float: right;
	margin: 0;
	&:after {
		font-family: FontAwesome;
        top:0;
        right: 0;
        color: @primary-color;
        line-height: 40px;
        font-size: 30px;
        content: "\f0c9"; 
	}
	&:hover {
	}
}
#menu-icon.active {
	background: @light-grey1;
	&:after {
			color: @dark-grey;
			content: "\f0d8"; 
		}
}
#menu-top {
	li {
		float: none;
		margin: 0;
		padding: 0;
	}
	a {
		font-size: 1.6rem;
		font-family: @titles-font;
		font-weight: 400;
		color: #fff;
		display: block;
		padding: 1.3rem 0rem 1.3rem 2rem;
		text-transform: uppercase;
		background: @top-submenu-bg;
		border-bottom: 1px solid @top-submenu-border;
		&:hover {
			background: darken(@top-submenu-bg, 10%);
			text-decoration: none;
		}
	}
	ul {
		width: 100%;
		position: static;
		display: block;
		padding: 0;
		li {
			a {
				padding: 1.3rem 1rem 1.3rem 3rem;
				text-transform: none;
				font-family: @main-font-family;
				font-size: 1.4rem;
			}
			ul {
				margin: 0;
				padding: 0;
				a {
					padding: 1.4rem 1rem 1.4rem 5rem;
					text-transform: none;
					font-size: 1.3rem;
				}
			}
		}
	}
	li.menu-current {
		>a {
			background: darken(@top-submenu-bg, 10%);
		}
	}
}