/*-----------------------------------
Side Navigation
---------------------------------*/
.sidenav {
	list-style: none;
	text-align: left;
	position: relative;
	width: 100%;
	margin: 0;
	padding: 0 0 3rem;
	border-top: 1px solid @border-grey;
	a {
	display: block;
	padding: 1.3rem 0.5rem 1.3rem 0rem;
	border-bottom: 1px solid @border-grey;
	color: @dark-grey;
	position: relative;
	font-weight: @font-normal;
		&:hover {
			text-decoration: none;
			color: @link-color;
		}
		&:before {
		font-family: FontAwesome;
        top:0;
        left: 0;
        padding-right: 1rem;
        content: "\f0da"; 
		}
	}
	li {
		padding: 0;
		margin: 0;
		list-style: none;
		ul {
			margin: 0;
			padding: 0;
			width: 100%;
			a {
				padding: 1.2rem 0.5rem 1.2rem 1.5rem;
				font-size: 1.3rem;
				&:before {
					font-family: FontAwesome;
        			top:0;
        			left: 0;
        			padding-right: 1rem;
        			content: "\f105"; 
				}
			}
		}
	}
	li.menu-current {
		 > a {
			text-decoration: none;
			color: @link-color;
		}
	}
}